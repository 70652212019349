import React, { useEffect } from "react";

function СalendlyForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/meet-erbis/erbis-intro-call?text_color=1c2f6b&primary_color=30c5ff&back=1&hide_gdpr_banner=1"
      style={{
        minWidth: "320px",
        height: "670px",
      }}
    />
  );
}

export default СalendlyForm;
